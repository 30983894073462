import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { Customer, Merchant } from '../types';

export type LoginState = {
  merchant?: Merchant;
  customer?: Customer;
};

export const loginSlice = createSlice({
  name: 'login',
  initialState: {},
  reducers: {
    setMerchant: (state: LoginState, action: PayloadAction<Merchant>) => {
      state.merchant = action.payload;
    },
    resetMerchant: (state: LoginState) => {
      state.merchant = undefined;
    },
    setCustomer: (state: LoginState, action: PayloadAction<Customer>) => {
      state.customer = action.payload;
    },
    resetCustomer: (state: LoginState) => {
      state.customer = undefined;
    },
  },
});

export const { actions } = loginSlice;

export default loginSlice.reducer;

export const merchantStateSelector = createSelector(
  (state: RootState) => state.login,
  (loginState: LoginState) => loginState.merchant
);

export const customerSelector = createSelector(
  (state: RootState) => state.login,
  (loginState: LoginState) => loginState.customer
);
