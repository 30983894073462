import React from 'react';
import { useInView } from 'react-intersection-observer';
import { PaperStatus } from 'src/components/AppWrapper/components/Paper/Paper';
import Button from 'src/components/lib/Button';
import ContainerFluid from 'src/components/lib/ContainerFluid/ContainerFluid';
import FlexContainer from 'src/components/lib/FlexContainer';
import ScrollWrapper from './ScrollWrapper';
import styles from './StickyBar.module.scss';

export type StickyBarProps = {
  buttonText: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  status: PaperStatus;
  disabled?: boolean;
  children?: React.ReactNode;
} & (
  | {
      doubleCta: 'false';
    }
  | {
      doubleCta: 'true';
      buttonTextSecondary: string;
      onClickSecondary: (e: React.MouseEvent<HTMLButtonElement>) => void;
    }
);

const StickyBar: React.FC<StickyBarProps> = (props: StickyBarProps) => {
  const { ref, inView } = useInView({
    threshold: 1,
  });

  let element: JSX.Element = <></>;
  if (props.doubleCta === 'false') {
    element = (
      <ScrollWrapper
        inView={inView}
        buttonText={props.buttonText}
        onClick={props.onClick}
        status={props.status}
        disabled={props.disabled}
        doubleCta={props.doubleCta}
      >
        <ContainerFluid className={styles.container}>
          <FlexContainer className={styles.container__button} ref={ref}>
            <Button
              primary={true}
              disabled={props.disabled}
              onClick={props.onClick}
            >
              {props.buttonText}
            </Button>
          </FlexContainer>
        </ContainerFluid>
      </ScrollWrapper>
    );
  }

  if (props.doubleCta === 'true') {
    element = (
      <ScrollWrapper
        inView={inView}
        buttonText={props.buttonText}
        buttonTextSecondary={props.buttonTextSecondary}
        onClick={props.onClick}
        onClickSecondary={props.onClickSecondary}
        status={props.status}
        disabled={props.disabled}
        doubleCta={props.doubleCta}
      >
        <ContainerFluid className={styles.container}>
          <FlexContainer className={styles.container__buttons} ref={ref}>
            <Button
              secondary={true}
              onClick={props.onClickSecondary}
              className={styles.container__buttons_ghost}
            >
              {props.buttonTextSecondary}
            </Button>
            <Button
              primary={true}
              disabled={props.disabled}
              onClick={props.onClick}
            >
              {props.buttonText}
            </Button>
          </FlexContainer>
        </ContainerFluid>
      </ScrollWrapper>
    );
  }

  return element;
};
export default StickyBar;
