import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { getCurrencySymbol } from 'src/utils/currency';
import {
  MAX_LOAN_AMOUNT,
  MIN_LOAN_AMOUNT,
  MIN_NUMBER_OF_INSTALMENTS,
} from '../constants';
import { BoundRange, LoanPlan, LoanSelection, SliderValues } from '../types';

export type LoanSimulationState = BoundRange & {
  amountValue: number;
  totalCost: number;
  productType: string;
  loanPlans: LoanPlan[];
  currency: string;
  isLoading: boolean;
  selectedNumberOfInstalments: number;
  isError: boolean;
  inputError?: string;
  selectedProductId?: string;
  selectedPlanId?: string;
};

export const createLoanDetailsSlice = (initialState: LoanSimulationState) =>
  createSlice({
    name: 'loanDetails',
    initialState,
    reducers: {
      setSliderValues: (
        currentState: LoanSimulationState,
        action: PayloadAction<SliderValues>
      ) => {
        currentState.lowerBound = action.payload.lowerBound;
        currentState.upperBound = action.payload.upperBound;
        currentState.amountValue = action.payload.amountValue;
      },
      setAmountValue: (
        currentState: LoanSimulationState,
        action: PayloadAction<number>
      ) => {
        currentState.amountValue = action.payload;
      },
      setTotalCost: (
        currentState: LoanSimulationState,
        action: PayloadAction<number>
      ) => {
        currentState.totalCost = action.payload;
      },
      setProductType: (
        currentState: LoanSimulationState,
        action: PayloadAction<string>
      ) => {
        currentState.productType = action.payload;
      },
      setSelection: (
        currentState: LoanSimulationState,
        action: PayloadAction<LoanSelection>
      ) => {
        currentState.selectedPlanId = action.payload.planId;
        currentState.selectedProductId = action.payload.productId;
        currentState.selectedNumberOfInstalments =
          action.payload.numberOfInstalments;
      },
      setLoanPlans: (
        currentState: LoanSimulationState,
        action: PayloadAction<LoanPlan[]>
      ) => {
        currentState.loanPlans = action.payload;
      },
      setCurrency: (
        currentState: LoanSimulationState,
        action: PayloadAction<string>
      ) => {
        currentState.currency = action.payload;
      },
      setIsLoading: (
        currentState: LoanSimulationState,
        action: PayloadAction<boolean>
      ) => {
        currentState.isLoading = action.payload;
      },
      setIsError: (
        currentState: LoanSimulationState,
        action: PayloadAction<boolean>
      ) => {
        currentState.isError = action.payload;
      },
      setInputError: (
        currentState: LoanSimulationState,
        action: PayloadAction<string | undefined>
      ) => {
        currentState.inputError = action.payload;
      },
    },
  });

export const initialState: LoanSimulationState = {
  lowerBound: 1000,
  upperBound: 10000,
  amountValue: parseFloat(
    `${(MAX_LOAN_AMOUNT - MIN_LOAN_AMOUNT) / 2 + MIN_LOAN_AMOUNT}`
  ),
  totalCost: 12000,
  productType: '',
  selectedProductId: '',
  loanPlans: [],
  currency: 'EUR',
  isLoading: true,
  isError: false,
  selectedNumberOfInstalments: MIN_NUMBER_OF_INSTALMENTS,
};

export const loanDetailsSlice = createLoanDetailsSlice(initialState);

export const { actions } = loanDetailsSlice;

export const amountValueSelector = createSelector(
  (state: RootState) => state.loanDetails,
  (state: LoanSimulationState) => state.amountValue
);

export const totalCostSelector = createSelector(
  (state: RootState) => state.loanDetails,
  (state: LoanSimulationState) => state.totalCost
);

export const productTypeSelector = createSelector(
  (state: RootState) => state.loanDetails,
  (state: LoanSimulationState) => state.productType
);

export const boundRangeSelector = createSelector(
  (state: RootState) => state.loanDetails,
  (state: LoanSimulationState): BoundRange => ({
    lowerBound: state.lowerBound,
    upperBound: state.upperBound,
  })
);

export const inputErrorSelector = createSelector(
  (state: RootState) => state.loanDetails,
  (state: LoanSimulationState) => state.inputError
);

export const loanPlansSelector = createSelector(
  (state: RootState) => state.loanDetails,
  (state: LoanSimulationState) => state.loanPlans
);

export const isLoadingSelector = createSelector(
  (state: RootState) => state.loanDetails,
  (state: LoanSimulationState) => state.isLoading
);

export const isErrorSelector = createSelector(
  (state: RootState) => state.loanDetails,
  (state: LoanSimulationState) => state.isError
);

export const selectedPlanIdSelector = createSelector(
  (state: RootState) => state.loanDetails,
  (state: LoanSimulationState) => state.selectedPlanId
);

export const selectedNumberOfInstalmentsSelector = createSelector(
  (state: RootState) => state.loanDetails,
  (state: LoanSimulationState) => state.selectedNumberOfInstalments
);

export const selectedPlanSelector = createSelector(
  (state: RootState) => state.loanDetails,
  (state: LoanSimulationState) =>
    state.loanPlans.find((plan) => plan.id === state.selectedPlanId)
);

export const currencySelector = createSelector(
  (state: RootState) => state.loanDetails,
  (state: LoanSimulationState) => state.currency
);

export const currencySymbolSelector = createSelector(
  (state: RootState) => state.loanDetails,
  (state: LoanSimulationState) => getCurrencySymbol(state.currency)
);

export const productIdSelector = createSelector(
  (state: RootState) => state.loanDetails,
  (state: LoanSimulationState) => state.selectedProductId
);

export default loanDetailsSlice.reducer;
