import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';

export type PartnerName =
  | 'BANCA_VALSABBINA'
  | 'BANCA_SELLA'
  | 'FAIREPAY'
  | 'SORGENIA'
  | '';
export type PartnerTitle = 'Faire Insights' | 'FairePay' | '';
export type PartnerFlow = 'INSIGHTS' | 'FAIREPAY';

export type PartnerState = {
  flow: PartnerFlow;
  partnerName: PartnerName;
  origin: string;
  allowedRoutes: string[];
};

const initialState: PartnerState = {
  flow: 'FAIREPAY',
  partnerName: 'FAIREPAY',
  origin: '',
  allowedRoutes: ['all'],
};

export const createPartnerSlice = (initialState: PartnerState) =>
  createSlice({
    name: 'partner',
    initialState,
    reducers: {
      setPartner: (
        state: PartnerState,
        action: PayloadAction<PartnerState>
      ) => {
        state.flow = action.payload.flow;
        state.origin = action.payload.origin;
        state.partnerName = action.payload.partnerName;
        state.allowedRoutes = action.payload.allowedRoutes;
      },
    },
  });

export const partnerSlice = createPartnerSlice(initialState);

export const { actions } = partnerSlice;

export const partnerFlowSelector = createSelector(
  (state: RootState) => state.partner,
  (state: PartnerState) => state.flow
);

export const partnerNameSelector = createSelector(
  (state: RootState) => state.partner,
  (state: PartnerState) => state.partnerName
);

export const allowedRoutesSelector = createSelector(
  (state: RootState) => state.partner,
  (state: PartnerState) => state.allowedRoutes
);

export default partnerSlice.reducer;
