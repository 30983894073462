import { IconComponent } from 'src/types/icon';

const Check: IconComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 13" {...props}>
    <defs>
      <linearGradient id="a" x1="0%" x2="100%" y1="50%" y2="50%">
        <stop offset="0%" stopColor="#6B33E3" />
        <stop offset="100%" stopColor="#FAAB8C" />
      </linearGradient>
    </defs>
    <path
      fill="url(#a)"
      d="M5.42393736 10.2516779 1.38143177 6.20917226 0 7.57606264 5.42393736 13 17.057047 1.36689038 15.6901566 0z"
      opacity=".60276617"
    />
  </svg>
);

export default Check;
