import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../slice';
import { Customer, Merchant } from '../types';

export type UseUserState = {
  setMerchant: (merchant: Merchant) => void;
  resetMerchant: () => void;
  setCustomer: (customer: Customer) => void;
  resetCustomer: () => void;
};

export const useLoginState = (): UseUserState => {
  const dispatch = useDispatch();

  const setMerchant = useCallback(
    (merchant: Merchant) => {
      dispatch(actions.setMerchant(merchant));
    },
    [dispatch]
  );

  const resetMerchant = useCallback(() => {
    dispatch(actions.resetMerchant());
  }, [dispatch]);

  const setCustomer = useCallback(
    (customer: Customer) => {
      dispatch(actions.setCustomer(customer));
    },
    [dispatch]
  );

  const resetCustomer = useCallback(() => {
    dispatch(actions.resetCustomer());
  }, [dispatch]);

  return {
    setMerchant,
    resetMerchant,
    setCustomer,
    resetCustomer,
  };
};
