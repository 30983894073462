import classNames from 'classnames';
import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import Loader from 'src/components/Loader';
import PatternSymbol from 'src/components/PatternSymbol';
import FlexContainer, {
  FlexContainerProps,
} from 'src/components/lib/FlexContainer';
import { partnerNameSelector } from 'src/features/partner/slice';
import useIcon from 'src/hooks/useIcon';
import PatternBg from '../PatternBg';
import styles from './Paper.module.scss';

export type PaperStatus = 'error' | 'success' | 'default';

export type PaperProps = FlexContainerProps & {
  header: React.ReactNode;
  subheader: React.ReactNode;
  note?: React.ReactNode;
  status?: PaperStatus;
  loader?: boolean;
  icon?: string;
  children?: React.ReactNode;
};

const applyStatusClassNames = (status: PaperStatus) => ({
  [styles.success]: status === 'success',
  [styles.error]: status === 'error',
  [styles.default]: status === 'default',
});

const Paper: React.FC<PaperProps> = ({
  children,
  header,
  subheader,
  note,
  loader,
  status = 'default',
  icon,
  ...props
}) => {
  const Icon = useIcon(icon);
  const partnerName = useSelector(partnerNameSelector);

  return (
    <FlexContainer
      {...props}
      className={classNames(
        styles.container,
        props.className,
        applyStatusClassNames(status)
      )}
      justifyContent="center"
      alignItems="center"
    >
      <FlexContainer
        flexDirection="column"
        className={styles.container__paperContent}
      >
        {Icon ? (
          <Icon
            width={4}
            className={classNames(applyStatusClassNames(status), styles.icon)}
          />
        ) : (
          <hr className={classNames(applyStatusClassNames(status))} /> &&
          partnerName === 'SORGENIA' || <hr className={classNames(applyStatusClassNames(status))} />
        )}
        <h1>{header}</h1>
        <Trans>
          <h3>{subheader}</h3>
        </Trans>
        {note && <p className={styles.note}>{note}</p>}
        {loader && <Loader className={styles.container__loader} size={120} />}
        {children}
      </FlexContainer>
      {partnerName === 'SORGENIA' || <PatternBg status={status} logoClassName={styles.container__pattern} /> }
      <PatternSymbol />
    </FlexContainer>
  );
};

export default Paper;
