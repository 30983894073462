import React from 'react';

const PatternSymbol: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
        <symbol id="pattern" viewBox="0 0 241 185">
          <g fill="none" fillRule="evenodd">
            <path
              fill="var(--pattern-default-one)"
              d="m183.8225 0-74.98556 185L53.28139 55.22897z"
            />
            <path
              fill="var(--pattern-default-two)"
              d="m240.68398 128.93939-74.98495 185-55.55617-129.77106z"
            />
            <path
              fill="var(--pattern-default-three)"
              d="m52.98563 55.25974-74.98563 185 130.54113-55.22886z"
            />
            <path
              fill="var(--pattern-default-four)"
              d="M109.342 185 184.32761 0l55.5555 129.77213z"
            />
          </g>
        </symbol>
        <symbol id="patternSuccess" viewBox="0 0 241 185">
          <g fill="none" fillRule="evenodd">
            <path
              fill="var(--pattern-success-one)"
              d="m183.8225 0-74.98556 185L53.28139 55.22897z"
            />
            <path
              fill="var(--pattern-success-two)"
              d="m240.68398 128.93939-74.98495 185-55.55617-129.77106z"
            />
            <path
              fill="var(--pattern-success-three)"
              d="m52.98563 55.25974-74.98563 185 130.54113-55.22886z"
            />
            <path
              fill="var(--pattern-success-four)"
              d="M109.342 185 184.32761 0l55.5555 129.77213z"
            />
          </g>
        </symbol>
        <symbol id="patternError" viewBox="0 0 241 185">
          <g fill="none" fillRule="evenodd">
            <path
              fill="var(--pattern-error-one)"
              d="m183.8225 0-74.98556 185L53.28139 55.22897z"
            />
            <path
              fill="var(--pattern-error-two)"
              d="m240.68398 128.93939-74.98495 185-55.55617-129.77106z"
            />
            <path
              fill="var(--pattern-error-three)"
              d="m52.98563 55.25974-74.98563 185 130.54113-55.22886z"
            />
            <path
              fill="var(--pattern-error-four)"
              d="M109.342 185 184.32761 0l55.5555 129.77213z"
            />
          </g>
        </symbol>
    </svg>
  );
};

export default PatternSymbol;
