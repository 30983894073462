import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';

export type RedirectPath =
  | '/'
  | '/login'
  | '/merchant'
  | '/help-form'
  | '/reset-password'
  | '/merchant/account-connection'
  | '/merchant/aml/personal-data'
  | '/merchant/loan-summary'
  | '/merchant/account-connection/success'
  | '/merchant/user-data'
  | '/merchant/user-data/send-again'
  | '/merchant/document-upload'
  | '/user'
  | '/user/account-connection'
  | '/user/account-connection/success'
  | '/user/aml/personal-data'
  | '/user/aml/success';

export type NotificationState = {
  header: string;
  subheader: string;
  ctaText: string;
  status: 'error' | 'success';
  doubleCta: 'true' | 'false';
  ctaRedirectPath: RedirectPath;
  ctaTextSecondary?: string;
  ctaRedirectPathSecondary?: RedirectPath;
};

export const initialNotificationState: NotificationState = {
  header: '',
  subheader: '',
  ctaText: '',
  status: 'error',
  doubleCta: 'false',
  ctaRedirectPath: '/',
  ctaTextSecondary: '',
  ctaRedirectPathSecondary: '/',
};

export const createNotificationSlice = (
  initialState: NotificationState = initialNotificationState
) =>
  createSlice({
    name: 'notification',
    initialState,
    reducers: {
      setNotification: (
        state: NotificationState,
        action: PayloadAction<NotificationState>
      ) => {
        state.header = action.payload.header;
        state.subheader = action.payload.subheader;
        state.ctaText = action.payload.ctaText;
        state.ctaRedirectPath = action.payload.ctaRedirectPath;
        state.status = action.payload.status;
        state.doubleCta = action.payload.doubleCta;
        state.ctaTextSecondary = action.payload.ctaTextSecondary
          ? action.payload.ctaTextSecondary
          : '';
        state.ctaRedirectPathSecondary = action.payload.ctaRedirectPathSecondary
          ? action.payload.ctaRedirectPathSecondary
          : '/';
      },
      resetNotification: (state: NotificationState) => {
        state.header = initialState.header;
        state.subheader = initialState.subheader;
        state.ctaText = initialState.ctaText;
        state.ctaRedirectPath = initialState.ctaRedirectPath;
        state.status = initialState.status;
        state.doubleCta = initialState.doubleCta;
        state.ctaTextSecondary = initialState.ctaTextSecondary;
        state.ctaRedirectPathSecondary = initialState.ctaRedirectPathSecondary;
      },
    },
  });

export const notificationSlice = createNotificationSlice();

export const { actions } = notificationSlice;

export default notificationSlice.reducer;

export const notificationSelector = createSelector(
  (state: RootState) => state.notification,
  (state) => state
);
