import classNames from 'classnames';
import React from 'react';
import Use from '../utils/Use';
import { PaperStatus } from '../Paper/Paper';
import styles from '../Paper/Paper.module.scss';

export type PatternBgProps = {
  logoClassName?: string;
  status: PaperStatus;
};

const hrefs: Record<PaperStatus, string> = {
  default: '#pattern',
  success: '#patternSuccess',
  error: '#patternError',
};

const PatternBg: React.FC<PatternBgProps> = ({ logoClassName, status }) => (
  <svg className={classNames(styles.pattern, logoClassName)}>
    <Use href={hrefs[status]} />
  </svg>
);

export default PatternBg;
