import React from 'react';
import ContainerFluid from 'src/components/lib/ContainerFluid/ContainerFluid';
import LogoLink from '../LogoLink';
import styles from './Header.module.scss';

const Header: React.FC = () => {
  return (
    <ContainerFluid className={styles.container}>
      <header className={styles.container__header}>
        <LogoLink
          placement="header"
          color="white"
          alt="FairePay logo header"
          logoClassName={styles.container__logo}
        />
      </header>
    </ContainerFluid>
  );
};

export default Header;
