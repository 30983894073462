import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import withSuspense from 'src/Routing/hocs/withSuspense';
import AppWrapper from 'src/components/AppWrapper';
import MessagePage from 'src/components/MessagePage';
import { merchantCheck } from 'src/features/login/api';
import { useLoginState } from 'src/features/login/hooks/useLoginState';
import { Merchant } from 'src/features/login/types';
import { notificationSelector } from 'src/features/notification/slice';
import PrivateRoute from './components/PrivateRoute';

const Login = withSuspense(React.lazy(() => import('src/features/login')));
const SetPassword = withSuspense(
  React.lazy(() => import('src/features/setPassword'))
);
const Error404 = withSuspense(
  React.lazy(() => import('src/features/error-404/Error404'))
);
const PrivacyPolicy = withSuspense(
  React.lazy(() => import('src/features/privacy-policy'))
);
const TermsAndConditions = withSuspense(
  React.lazy(() => import('src/features/terms-conditions'))
);
const PasswordReset = withSuspense(
  React.lazy(() => import('src/features/passwordReset'))
);
const MerchantHome = withSuspense(
  React.lazy(() => import('src/features/merchant/home'))
);
const UserHome = withSuspense(
  React.lazy(() => import('src/features/user/home'))
);
const MerchantLoanDetails = withSuspense(
  React.lazy(() => import('src/features/merchant/loanDetails'))
);
const AccountConnection = withSuspense(
  React.lazy(
    () => import('src/features/user/account-connection/AccountConnection')
  )
);
const AccountConnectionRedirect = withSuspense(
  React.lazy(
    () => import('src/features/user/account-connection/ConnectionRedirect')
  )
);
const AccountConnectionRedirectError = withSuspense(
  React.lazy(
    () => import('src/features/user/account-connection/ConnectionRedirectError')
  )
);
const AccountConnectionSuccess = withSuspense(
  React.lazy(
    () => import('src/features/user/account-connection/ConnectionSuccessful')
  )
);

const UserDataRecap = withSuspense(
  React.lazy(() => import('src/features/user/user-recap'))
);

const UserLoanRecap = withSuspense(
  React.lazy(() => import('src/features/user/loan-recap'))
);
const MerchantUserData = withSuspense(
  React.lazy(() => import('src/features/merchant/userData'))
);
const MerchantDocumentUpload = withSuspense(
  React.lazy(() => import('src/features/merchant/documentUpload'))
);
const MerchantUserDataSubmitSuccess = withSuspense(
  React.lazy(() => import('src/features/merchant/userDataSubmitSuccess'))
);
const MerchantUserDataSubmitSendAgain = withSuspense(
  React.lazy(() => import('src/features/merchant/userDataSubmitSendAgain'))
);
const MerchantUserDataSubmitSendAgainConfirm = withSuspense(
  React.lazy(
    () => import('src/features/merchant/userDataSubmitSendAgainConfirm')
  )
);
//FIXME: Hide AML Pages: refers to FC-2247
/*const AmlEmploymentData = withSuspense(
  React.lazy(() => import('src/features/user/aml/employmentData'))
);
const AmlPersonalData = withSuspense(
  React.lazy(() => import('src/features/user/aml/personalData'))
  );
const AmlSubmitError = withSuspense(
  React.lazy(() => import('src/features/user/aml/amlSubmitError'))
);
*/
const AmlSuccess = withSuspense(
  React.lazy(() => import('src/features/user/aml/amlSuccess'))
);

const SpidIdentificationSuccess = withSuspense(
  React.lazy(() => import('src/features/user/spid/IdentificationSuccessful'))
);
const SpidRedirect = withSuspense(
  React.lazy(() => import('src/features/user/spid/SpidRedirect'))
);
{
  /*
  FIXME: Don't remove it 🚫🚫🚫 De-comment when spid process is handled.
  Identification type hidden temporarily for Sella personal credit (reference to FC-1943)
  const MerchantIdentificationMethod = withSuspense(
  React.lazy(() => import('src/features/merchant/identificationMethod'))
);*/
}
const HelpForm = withSuspense(
  React.lazy(() => import('src/features/help-form'))
);

const ScrollToTop: React.VFC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

const Routing = () => {
  const { setMerchant, resetMerchant } = useLoginState();
  const {
    header,
    subheader,
    ctaText,
    ctaRedirectPath,
    status,
    doubleCta,
    ctaRedirectPathSecondary,
    ctaTextSecondary,
  } = useSelector(notificationSelector);
  const { isFetching: isMerchantFetching, isLoading: isMerchantLoading } =
    useQuery(['/me'], merchantCheck, {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (merchant: Merchant) => {
        setMerchant(merchant);
      },
      onError: () => {
        resetMerchant();
      },
    });

  const isAwaiting = isMerchantFetching || isMerchantLoading;

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<AppWrapper isLoading={isAwaiting} />}>
          <Route
            path={status === 'error' ? 'error' : 'success'}
            element={
              doubleCta === 'false' ? (
                <MessagePage
                  header={header}
                  subheader={subheader}
                  ctaText={ctaText}
                  ctaRedirectPath={ctaRedirectPath}
                  status={status}
                  doubleCta={'false'}
                />
              ) : (
                <MessagePage
                  header={header}
                  subheader={subheader}
                  ctaText={ctaText}
                  ctaRedirectPath={ctaRedirectPath}
                  status={status}
                  doubleCta={'true'}
                  ctaRedirectPathSecondary={
                    ctaRedirectPathSecondary ? ctaRedirectPathSecondary : '/'
                  }
                  ctaTextSecondary={ctaTextSecondary ?? ''}
                />
              )
            }
          />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="*" element={<Error404 />} />
          <Route index element={<Login />} />
          <Route path="reset-password">
            <Route index element={<PasswordReset />} />
          </Route>
          <Route path="login" element={<Login />} />
          <Route path="set-password">
            <Route index element={<SetPassword />} />
          </Route>
          <Route path="merchant" element={<PrivateRoute />}>
            <Route index element={<MerchantHome />} />
            <Route path="loan-data" element={<MerchantLoanDetails />} />
            {/*
            // FIXME: Don't remove it 🚫🚫🚫 De-comment when spid process is handled.
            <Route
              path="identification-method"
              element={<MerchantIdentificationMethod />}
            />*/}
            <Route path="user-data">
              <Route index element={<MerchantUserData />} />
              <Route
                path="success"
                element={<MerchantUserDataSubmitSuccess />}
              />
              <Route
                path="send-again"
                element={<MerchantUserDataSubmitSendAgain />}
              />
              <Route
                path="send-again-confirm"
                element={<MerchantUserDataSubmitSendAgainConfirm />}
              />
            </Route>
            <Route path="document-upload">
              <Route index element={<MerchantDocumentUpload />} />
            </Route>
          </Route>
          <Route path="user">
            <Route>
              <Route index element={<UserHome />} />
              <Route path="user-recap" element={<UserDataRecap />} />
              <Route path="loan-recap" element={<UserLoanRecap />} />
            </Route>
            <Route path="account-connection">
              <Route index element={<AccountConnection />} />
              <Route path="redirect" element={<AccountConnectionRedirect />} />
              <Route
                path="redirect-error"
                element={<AccountConnectionRedirectError />}
              />
              <Route path="success" element={<AccountConnectionSuccess />} />
            </Route>
            <Route path="aml">
              {/*FIXME: Hide AML Pages: refers to FC-2247
              <Route path="employment-data" element={<AmlEmploymentData />} />
              <Route path="personal-data" element={<AmlPersonalData />} />
              <Route path="error" element={<AmlSubmitError />} />
            */}
              {/* TODO: AMLSuccess to delete later when the above components are decommented. */}
              <Route path="success" element={<AmlSuccess />} />
            </Route>
            <Route path="spid">
              <Route path="redirect" element={<SpidRedirect />} />
              {/* TODO: AMLSuccess to delete later when the AML components are decommented. */}
              <Route path="success" element={<SpidIdentificationSuccess />} />
            </Route>
          </Route>
          <Route path="help-form">
            <Route index element={<HelpForm />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
