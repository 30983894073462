import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RadioAnswer } from '../factories/createPepRadio';
import { AmlRequestPayload } from '../types';

export type EmploymentDataState = Pick<
  AmlRequestPayload,
  'role' | 'activityType'
> & {
  lastHiringMonth: string;
  lastHiringYear: string;
  isPep: RadioAnswer;
  isLip: RadioAnswer;
};

export const createEmploymentDataSlice = (initialState: EmploymentDataState) =>
  createSlice({
    name: 'amlEmploymentData',
    initialState,
    reducers: {
      setEmploymentData: (
        state: EmploymentDataState,
        action: PayloadAction<EmploymentDataState>
      ) => {
        const { payload } = action;
        state.activityType = payload.activityType;
        state.role = payload.role;
        state.isLip = payload.isLip;
        state.isPep = payload.isPep;
        state.lastHiringMonth = payload.lastHiringMonth;
        state.lastHiringYear = payload.lastHiringYear;
      },
    },
  });

export const initialEmploymentDataState: EmploymentDataState = {
  role: '',
  activityType: '',
  isLip: 'no',
  isPep: 'no',
  lastHiringMonth: '',
  lastHiringYear: '',
};

export const employmentDataSlice = createEmploymentDataSlice(
  initialEmploymentDataState
);

export const { actions } = employmentDataSlice;

export default employmentDataSlice.reducer;
