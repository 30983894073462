import {
  PartnerFlow,
  PartnerName,
  PartnerState,
  PartnerTitle,
} from 'src/features/partner/slice';

export const getTitleByPartner = (partnerName: PartnerName): PartnerTitle => {
  let partnerTitle: PartnerTitle = '';
  const isInsighits = getFlowByPartner(partnerName) === 'INSIGHTS';
  if (isInsighits) {
    partnerTitle = 'Faire Insights';
  } else {
    partnerTitle = 'FairePay';
  }
  return partnerTitle;
};

export const getFlowByPartner = (partnerName: PartnerName): PartnerFlow => {
  let flow: PartnerFlow = 'FAIREPAY';
  if (partnerName === 'BANCA_VALSABBINA' || partnerName === 'SORGENIA') {
    flow = 'INSIGHTS';
  }
  return flow;
};

type InsightRoutes = [
  '/',
  '/merchant',
  '/merchant/user-data',
  '/success',
  '/error'
];

export const getAllowedRoutesByFlow = (flow: PartnerFlow): string[] => {
  if (flow === 'INSIGHTS') {
    const insightRoutes: InsightRoutes = [
      '/',
      '/merchant',
      '/merchant/user-data',
      '/success',
      '/error',
    ];
    return insightRoutes;
  }
  const fairepayRoutes: ['all'] = ['all'];
  return fairepayRoutes;
};

export const calcIsAllowedRoute = (
  allowedRoutes: string[],
  location: string
): boolean => {
  let isAllowedRoute =
    allowedRoutes
      .map((route) => route.endsWith(location))
      .filter((route) => route === true).length > 0;
  isAllowedRoute = isAllowedRoute || allowedRoutes.includes('all');
  return isAllowedRoute;
};

export const createPartnerInitialState = (
  partnerName: PartnerName
): PartnerState => {
  const flow = getFlowByPartner(partnerName);
  const response: PartnerState = {
    flow,
    partnerName,
    origin,
    allowedRoutes: getAllowedRoutesByFlow(flow),
  };
  return response;
};
