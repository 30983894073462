import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MaritalStatus, ResidentialAddress } from '../../types';

export type PersonalDataState = ResidentialAddress & {
  maritalStatus?: MaritalStatus;
};

export const createPersonalDataSlice = (initialState: PersonalDataState) =>
  createSlice({
    name: 'amlPersonalData',
    initialState,
    reducers: {
      setPersonalData: (
        state: PersonalDataState,
        action: PayloadAction<PersonalDataState>
      ) => {
        const { payload } = action;
        state.maritalStatus = payload.maritalStatus;
        state.city = payload.city;
        state.houseNumber = payload.houseNumber;
        state.postalCode = payload.postalCode;
        state.street = payload.street;
        state.province = payload.province;
      },
    },
  });

export const initialPersonalDataState: PersonalDataState = {
  maritalStatus: undefined,
  city: '',
  houseNumber: '',
  postalCode: '',
  street: '',
  province: '',
};

export const personalDataSlice = createPersonalDataSlice(
  initialPersonalDataState
);

export const { actions } = personalDataSlice;

export default personalDataSlice.reducer;
