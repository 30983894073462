import { IconComponent } from 'src/types/icon';
import Close from './close/Close';
import Info from './info/Info';
import Interrogative from './interrogative/Interrogative';
import Chevron from './chevron/Chevron';
import Pencil from './pencil/Pencil';
import User from './user/User';
import Check from './check/Check';
import Pulse from './pulse/Pulse';
import Exclamation from './exclamation/Exclamation';

export type IconMap = Record<string, IconComponent>;

export const iconMap: IconMap = {
  Chevron,
  Close,
  Info,
  Interrogative,
  Check,
  Pulse,
  Exclamation,
  Pencil,
  User,
};
