import React from 'react';
import { PaperStatus } from 'src/components/AppWrapper/components/Paper/Paper';
import FormSubmitBar from '../FormSubmitBar';

export type ScrollWrapperProps = {
  inView: boolean;
  buttonText: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  status: PaperStatus;
  disabled?: boolean;
  children?: React.ReactNode;
} & (
  | {
      doubleCta: 'false';
    }
  | {
      doubleCta: 'true';
      buttonTextSecondary: string;
      onClickSecondary: (e: React.MouseEvent<HTMLButtonElement>) => void;
    }
);

const ScrollWrapper: React.FC<ScrollWrapperProps> = (
  props: ScrollWrapperProps
) => {
  let element: JSX.Element = <></>;
  if (props.doubleCta === 'false')
    element = (
      <>
        {props.inView ? null : (
          <FormSubmitBar
            status={props.status}
            onClick={props.onClick}
            buttonText={props.buttonText}
            disabled={props.disabled}
            doubleCta={props.doubleCta}
          />
        )}
        {props.children}
      </>
    );

  if (props.doubleCta === 'true')
    element = (
      <>
        {props.inView ? null : (
          <FormSubmitBar
            status={props.status}
            onClick={props.onClick}
            onClickSecondary={props.onClickSecondary}
            buttonText={props.buttonText}
            buttonTextSecondary={props.buttonTextSecondary}
            disabled={props.disabled}
            doubleCta={props.doubleCta}
          />
        )}
        {props.children}
      </>
    );

  return element;
};
export default ScrollWrapper;
