import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { PaperStatus } from 'src/components/AppWrapper/components/Paper/Paper';
import Button from 'src/components/lib/Button';
import FlexContainer from 'src/components/lib/FlexContainer';
import OutsideRenderer from 'src/components/lib/OutsideRenderer';
import { partnerNameSelector } from 'src/features/partner/slice';
import styles from './FormSubmitBar.module.scss';

export type FormSubmitBarProps = {
  buttonText: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  status: PaperStatus;
  disabled?: boolean;
} & (
  | {
      doubleCta: 'false';
    }
  | {
      doubleCta: 'true';
      buttonTextSecondary: string;
      onClickSecondary: (e: React.MouseEvent<HTMLButtonElement>) => void;
    }
);

const applyStatusClassNames = (status: PaperStatus) => ({
  [styles.success]: status === 'success',
  [styles.error]: status === 'error',
  [styles.default]: status === 'default',
});

const FormSubmitBar: React.VFC<FormSubmitBarProps> = (
  props: FormSubmitBarProps
) => {
  const partnerTheme = useSelector(partnerNameSelector);
  return (
    <OutsideRenderer>
      {props.doubleCta === 'true' ? (
        <FlexContainer
          data-theme={partnerTheme}
          alignItems="center"
          justifyContent="center"
          className={classNames(
            applyStatusClassNames(props.status),
            styles.container__stickys
          )}
        >
          <Button
            type="submit"
            secondary={true}
            onClick={props.onClickSecondary}
          >
            {props.buttonTextSecondary}
          </Button>
          <Button
            type="submit"
            primary={true}
            onClick={props.onClick}
            disabled={props.disabled}
          >
            {props.buttonText}
          </Button>
        </FlexContainer>
      ) : (
        <FlexContainer
          data-theme={partnerTheme}
          alignItems="center"
          justifyContent="center"
          className={classNames(
            applyStatusClassNames(props.status),
            styles.container__sticky
          )}
        >
          <Button
            type="submit"
            primary={true}
            onClick={props.onClick}
            disabled={props.disabled}
          >
            {props.buttonText}
          </Button>
        </FlexContainer>
      )}
    </OutsideRenderer>
  );
};

export default FormSubmitBar;
