import classNames from 'classnames';
import React from 'react';
import useIcon from 'src/hooks/useIcon';
import styles from './TextLink.module.scss';

export type TextLinkProps = React.AnchorHTMLAttributes<any> & {
  className?: string;
  id?: string;
  title?: string;
  disabled?: boolean;
  icon?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLAnchorElement>) => void;
  children?: React.ReactNode;
};

const TextLink: React.FC<TextLinkProps> = ({
  id,
  title,
  onClick,
  onKeyDown,
  className,
  children,
  disabled,
  icon,
  ...props
}) => {
  const Icon = useIcon(icon);

  return (
    <a
      data-testid={id}
      id={id}
      title={title}
      onClick={onClick}
      onKeyDown={onKeyDown}
      className={classNames(
        styles.container,
        { [styles.disabled]: disabled },
        className
      )}
      {...props}
    >
      {Icon ? (
        <Icon
          width={12}
          className={classNames(styles.container__icon, {
            [styles.disabled]: disabled,
          })}
        />
      ) : null}
      {React.Children.count(children) > 0 && (
        <span
          className={classNames(styles.container__children, {
            [styles.noIcon]: !Icon,
          })}
        >
          {children}
        </span>
      )}
    </a>
  );
};

export default TextLink;
