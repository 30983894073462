import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import sorgeniaLogo from 'src/components/LogoSymbol/partner-logo/sorgenia-logo.svg';
import { partnerFlowSelector } from 'src/features/partner/slice';
import Use from '../utils/Use';
import { usePartner } from '../utils/usePartner';
import styles from './LogoLink.module.scss';

export type LogoLinkProps = {
  linkClassName?: string;
  logoClassName?: string;
  color: 'white' | 'black' | 'gradient';
  placement: 'header' | 'footer';
  alt: string;
};

const hrefs: Record<LogoLinkProps['placement'], string> = {
  header: '#logo',
  footer: '#logoColored',
};

const LogoLink: React.FC<LogoLinkProps> = ({
  linkClassName,
  logoClassName,
  color,
  placement,
}) => {
  const partnerFlow = useSelector(partnerFlowSelector);
  const location = window.location.origin;
  const { partnerName } = usePartner(location);

  return (
    <>
      {partnerName === 'SORGENIA' ? (
        <img
          className={classNames(styles.logo, [styles.logoSorgenia])}
          src={sorgeniaLogo}
          alt={partnerFlow}
        />
      ) : (
        <NavLink to="/" className={linkClassName}>
          <svg
            className={classNames(
              styles.logo,
              {
                [styles.white]: color === 'white',
                [styles.black]: color === 'black',
                [styles.gradient]: color === 'gradient',
              },
              logoClassName
            )}
          >
            <Use href={hrefs[placement]} />
          </svg>
        </NavLink>
      )}
    </>
  );
};

export default LogoLink;
