import classNames from 'classnames';
import React from 'react';
import FlexContainer from 'src/components/lib/FlexContainer';
import styles from './ContainerFluid.module.scss';

export type ContainerFluidProps = {
  className?: string;
  children?: React.ReactNode;
};

const ContainerFluid: React.FC<ContainerFluidProps> = ({
  children,
  className,
}) => (
  <FlexContainer
    className={classNames(styles.container, className)}
    alignItems="center"
    justifyContent="center"
  >
    {children}
  </FlexContainer>
);

export default ContainerFluid;
