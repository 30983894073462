import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RedirectPath, actions } from 'src/features/notification/slice';
import Paper from '../AppWrapper/components/Paper';
import { PaperStatus } from '../AppWrapper/components/Paper/Paper';
import StickyBar from '../lib/StickyBar';

export type MessagePageProps = {
  header: string;
  subheader: string | JSX.Element;
  ctaText: string;
  ctaRedirectPath: RedirectPath;
  status: PaperStatus;
  note?: React.ReactNode;
} & (
  | {
      doubleCta: 'false';
    }
  | {
      doubleCta: 'true';
      ctaTextSecondary: string;
      ctaRedirectPathSecondary: RedirectPath;
    }
);

const MessagePage: React.FC<MessagePageProps> = (props: MessagePageProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOnClick = () => {
    dispatch(actions.resetNotification());
    navigate(props.ctaRedirectPath);
  };

  switch (props.doubleCta) {
    case 'true': {
      const handleOnClickSecondary = () => {
        dispatch(actions.resetNotification());
        navigate(props.ctaRedirectPathSecondary);
      };

      return (
        <Paper
          header={props.header}
          subheader={props.subheader}
          status={props.status}
          note={props.note}
          icon={props.status === 'error' ? 'Exclamation' : ''}
        >
          <StickyBar
            status={props.status}
            buttonText={props.ctaText}
            onClick={handleOnClick}
            doubleCta="true"
            onClickSecondary={handleOnClickSecondary}
            buttonTextSecondary={props.ctaTextSecondary}
          />
        </Paper>
      );
    }
    case 'false': {
      return (
        <Paper
          header={props.header}
          subheader={props.subheader}
          status={props.status}
          note={props.note}
          icon={props.status === 'error' ? 'Exclamation' : ''}
        >
          <StickyBar
            status={props.status}
            buttonText={props.ctaText}
            onClick={handleOnClick}
            doubleCta="false"
          />
        </Paper>
      );
    }
  }
};

export default MessagePage;
