import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import Header from 'src/components/AppWrapper/components/Header';
import { PartnerName, actions } from 'src/features/partner/slice';
import {
  getAllowedRoutesByFlow,
  getFlowByPartner,
  getTitleByPartner,
} from 'src/utils/partner';
import Loader from '../Loader';
import Footer from './components/Footer';
import HelpLink from './components/HelpLink';
import Main from './components/Main';
import Paper from './components/Paper';
import { usePartner } from './components/utils/usePartner';

export type Props = {
  isLoading: boolean;
};

const AppWrapper: React.FC<Props> = ({ isLoading }) => {
  const location = window.location.origin;
  const { partnerName } = usePartner(location);
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = getTitleByPartner(partnerName);
  }, [partnerName]);

  useEffect(() => {
    const dispatchPartnerAction = (partnerName: PartnerName) => {
      const flow = getFlowByPartner(partnerName);
      dispatch(
        actions.setPartner({
          flow,
          origin: location,
          partnerName,
          allowedRoutes: getAllowedRoutesByFlow(flow),
        })
      );
    };
    dispatchPartnerAction(partnerName);
  }, [partnerName]);

  return (
    <>
      <div className="App" data-theme={partnerName}>
        <Header />
        <Main>
          {isLoading ? (
            <Paper header="" subheader="">
              <Loader size={120} />
            </Paper>
          ) : (
            <Outlet />
          )}
        </Main>
        {partnerName !== 'SORGENIA' ? <HelpLink /> : null}
        <Footer />
      </div>
    </>
  );
};

export default AppWrapper;
