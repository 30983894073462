import React from 'react';

type Props = {
  href: string;
};

const Use: React.FC<Props> = ({ href }) => (
  <use href={href} preserveAspectRatio="xMidYMid meet" />
);

export default Use;
