import { useEffect, useState } from 'react';
import { Localhost, fairepayUrl, insightsUrl, sorgeniaUrl } from 'src/axios';
import { PartnerName } from 'src/features/partner/slice';

export const usePartner = (location: string) => {
  const [partnerName, setPartnerName] = useState<PartnerName>('');
  const isBancaSella =
    location === fairepayUrl ||
    location === Localhost.Fairepay ||
    location === Localhost.Banca_Sella;
  const isBancaValsabbina =
    location === insightsUrl || location === Localhost.Banca_Valsabbina;
  const isSorgenia =
    location === sorgeniaUrl || location === Localhost.Sorgenia;

  useEffect(() => {
    const setPartner = () => {
      if (isBancaSella) {
        setPartnerName('BANCA_SELLA');
      }
      if (isBancaValsabbina) {
        setPartnerName('BANCA_VALSABBINA');
      }
      if (isSorgenia) {
        setPartnerName('SORGENIA');
      }
    };
    setPartner();
  }, []);

  return {
    partnerName,
  };
};
