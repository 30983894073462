import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { merchantStateSelector } from 'src/features/login/slice';

const PrivateRoute = () => {
  const merchant = useSelector(merchantStateSelector);

  if (!merchant || !merchant.id) {
    return <Navigate to="/" replace={true} />;
  }
  return <Outlet />;
};

export default PrivateRoute;
