import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import ContainerFluid from 'src/components/lib/ContainerFluid/ContainerFluid';
import FlexContainer from 'src/components/lib/FlexContainer';
import TextLink from 'src/components/lib/TextLink';
import styles from './HelpLink.module.scss';

const HelpLink: React.VFC = () => {
  const match = useMatch('/help-form/*');
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate('/help-form');
  }, [navigate]);

  if (match) {
    return null;
  }

  return (
    <ContainerFluid className={styles.container}>
      <FlexContainer
        alignItems="center"
        justifyContent="flex-end"
        className={styles.container__row}
      >
        <Trans
          t={t}
          i18nKey="common:help_link.text"
          components={[
            <TextLink
              id="help-link"
              onClick={onClick}
              className={styles.textLink}
              icon="Interrogative"
            />,
          ]}
        />
      </FlexContainer>
    </ContainerFluid>
  );
};

export default HelpLink;
