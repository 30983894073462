import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import error404En from 'src/features/error-404/translations/en.json';
import error404It from 'src/features/error-404/translations/it.json';
import helpFormEn from 'src/features/help-form/translations/en.json';
import helpFormIt from 'src/features/help-form/translations/it.json';
import loginEn from 'src/features/login/translations/translations.en.json';
import loginIt from 'src/features/login/translations/translations.it.json';
import merchantSubmitErrorEn from 'src/features/merchant/components/SubmitError/translations/translations.en.json';
import merchantSubmitErrorIt from 'src/features/merchant/components/SubmitError/translations/translations.it.json';
import merchantDocumentUploadEn from 'src/features/merchant/documentUpload/translations/translations.en.json';
import merchantDocumentUploadIt from 'src/features/merchant/documentUpload/translations/translations.it.json';
import merchantHomeEn from 'src/features/merchant/home/translations/translations.en.json';
import merchantHomeIt from 'src/features/merchant/home/translations/translations.it.json';
import userIdentificationMethodEn from 'src/features/merchant/identificationMethod/translations/en.json';
import identificationMethodIt from 'src/features/merchant/identificationMethod/translations/it.json';
import merchantLoanDetailsEn from 'src/features/merchant/loanDetails/translations/translations.en.json';
import merchantLoanDetailsIt from 'src/features/merchant/loanDetails/translations/translations.it.json';
import merchantPathSuccessEn from 'src/features/merchant/merchantSuccess/translations/translations.en.json';
import merchantPathSuccessIt from 'src/features/merchant/merchantSuccess/translations/translations.it.json';
import merchantUserDataEn from 'src/features/merchant/userData/translations/translations.en.json';
import merchantUserDataIt from 'src/features/merchant/userData/translations/translations.it.json';
import merchantUserDataSendAgainEn from 'src/features/merchant/userDataSubmitSendAgain/translations/en.json';
import merchantUserDataSendAgainIt from 'src/features/merchant/userDataSubmitSendAgain/translations/it.json';
import merchantUserDataSendAgainConfirmEn from 'src/features/merchant/userDataSubmitSendAgainConfirm/translations/en.json';
import merchantUserDataSendAgainConfirmIt from 'src/features/merchant/userDataSubmitSendAgainConfirm/translations/it.json';
import merchantUserDataSuccessEn from 'src/features/merchant/userDataSubmitSuccess/translations/en.json';
import merchantUserDataSuccessIt from 'src/features/merchant/userDataSubmitSuccess/translations/it.json';
import passwordResetEn from 'src/features/passwordReset/translations/translations.en.json';
import passwordResetIt from 'src/features/passwordReset/translations/translations.it.json';
import privacyEn from 'src/features/privacy-policy/translations/en.json';
import privacyIt from 'src/features/privacy-policy/translations/it.json';
import setPasswordEn from 'src/features/setPassword/translations/translations.en.json';
import setPasswordIt from 'src/features/setPassword/translations/translations.it.json';
import termsEn from 'src/features/terms-conditions/translations/en.json';
import termsIt from 'src/features/terms-conditions/translations/it.json';
import userAccountConnectionEn from 'src/features/user/account-connection/translations/translations.en.json';
import userAccountConnectionIt from 'src/features/user/account-connection/translations/translations.it.json';
import amlSubmitErrorEn from 'src/features/user/aml/amlSubmitError/translations/translations.en.json';
import amlSubmitErrorIt from 'src/features/user/aml/amlSubmitError/translations/translations.it.json';
import amlSuccessEn from 'src/features/user/aml/amlSuccess/translations/translations.en.json';
import amlSuccessIt from 'src/features/user/aml/amlSuccess/translations/translations.it.json';
import amlEmploymentDataEn from 'src/features/user/aml/employmentData/translations/translations.en.json';
import amlEmploymentDataIt from 'src/features/user/aml/employmentData/translations/translations.it.json';
import amlPersonalDataEn from 'src/features/user/aml/personalData/translations/translations.en.json';
import amlPersonalDataIt from 'src/features/user/aml/personalData/translations/translations.it.json';
import userHomeEn from 'src/features/user/home/translations/translations.en.json';
import userHomeIt from 'src/features/user/home/translations/translations.it.json';
import userLoanRecapEn from 'src/features/user/loan-recap/translations/en.json';
import userLoanRecapIt from 'src/features/user/loan-recap/translations/it.json';
import spidPathSuccessEn from 'src/features/user/spid/IdentificationSuccessful/translations/translations.en.json';
import spidPathSuccessIt from 'src/features/user/spid/IdentificationSuccessful/translations/translations.it.json';
import spidRedirectEn from 'src/features/user/spid/SpidRedirect/translations/en.json';
import spidRedirectIt from 'src/features/user/spid/SpidRedirect/translations/it.json';
import userRecapEn from 'src/features/user/user-recap/translations/en.json';
import userRecapIt from 'src/features/user/user-recap/translations/it.json';
import commonEn from 'src/translations/common.en.json';
import commonIt from 'src/translations/common.it.json';
import flowstepsEn from 'src/translations/flowsteps.en.json';
import flowstepsIt from 'src/translations/flowsteps.it.json';


export const defaultNS = 'it';

// type Locale = "en" | "it";
// type Resources = {
//   [key in Locale]: {
//     translation: typeof en & typeof it;
//   };
// };
export const resources = {
  en: {
    login: loginEn,
    'privacy-policy': privacyEn,
    'terms-conditions': termsEn,
    'error-404': error404En,
    'set-password': setPasswordEn,
    'reset-password': passwordResetEn,
    'merchant/home': merchantHomeEn,
    'user/home': userHomeEn,
    'user-recap': userRecapEn,
    'loan-recap': userLoanRecapEn,
    'merchant/loan-data': merchantLoanDetailsEn,
    'merchant/user-data': merchantUserDataEn,
    'merchant/user-data/success': merchantUserDataSuccessEn,
    'merchant/user-data/send-again': merchantUserDataSendAgainEn,
    'merchant/user-data/send-again-confirm': merchantUserDataSendAgainConfirmEn,
    'merchant/document-upload': merchantDocumentUploadEn,
    'merchant/submit-error': merchantSubmitErrorEn,
    'merchant/success': merchantPathSuccessEn,
    'merchant/identification-method': userIdentificationMethodEn,
    'user/account-connection': userAccountConnectionEn,
    'user/aml/employment-data': amlEmploymentDataEn,
    'user/aml/personal-data': amlPersonalDataEn,
    'user/aml/error': amlSubmitErrorEn,
    'user/aml/success': amlSuccessEn,
    'user/spid/success': spidPathSuccessEn,
    'user/spid/redirect': spidRedirectEn,
    'help-form': helpFormEn,
    common: commonEn,
    flowsteps: flowstepsEn,
  },
  it: {
    login: loginIt,
    'privacy-policy': privacyIt,
    'terms-conditions': termsIt,
    'error-404': error404It,
    'set-password': setPasswordIt,
    'reset-password': passwordResetIt,
    'merchant/home': merchantHomeIt,
    'user/home': userHomeIt,
    'user-recap': userRecapIt,
    'loan-recap': userLoanRecapIt,
    'merchant/loan-data': merchantLoanDetailsIt,
    'merchant/user-details': merchantUserDataIt,
    'merchant/document-upload': merchantDocumentUploadIt,
    'merchant/user-data': merchantUserDataIt,
    'merchant/user-data/success': merchantUserDataSuccessIt,
    'merchant/user-data/send-again': merchantUserDataSendAgainIt,
    'merchant/user-data/send-again-confirm': merchantUserDataSendAgainConfirmIt,
    'merchant/submit-error': merchantSubmitErrorIt,
    'merchant/success': merchantPathSuccessIt,
    'merchant/identification-method': identificationMethodIt,
    'user/account-connection': userAccountConnectionIt,
    'user/aml/employment-data': amlEmploymentDataIt,
    'user/aml/personal-data': amlPersonalDataIt,
    'user/spid/success': spidPathSuccessIt,
    'user/aml/error': amlSubmitErrorIt,
    'user/aml/success': amlSuccessIt,
    'user/spid/redirect': spidRedirectIt,
    'help-form': helpFormIt,
    common: commonIt,
    flowsteps: flowstepsIt,
  },
};

export const initTranslations = (): void => {
  i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      resources,
      defaultNS: defaultNS,
      fallbackLng: 'it',
      supportedLngs: ['en', 'it', 'it-it', 'it-IT'],
      keySeparator: false,
      interpolation: {
        escapeValue: false,
      },
      detection: {
        order: [
          'querystring',
          'cookie',
          'navigator',
          'htmlTag',
          'path',
          'subdomain',
        ],
      },
      returnObjects: true,
    });
};

initTranslations();

export default i18n;