import {
  createSelector,
  createSlice,
  PayloadAction,
  Slice,
} from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { Agreements } from '../types';

export type UserDataScreenAgreements = Omit<
  Agreements,
  'creditEvaluationConsent'
>;

export type UserAgreementsState = {
  agreements: UserDataScreenAgreements;
};

export const initialState: UserAgreementsState = {
  agreements: {
    dataTreatment: false,
    consumerProfiling: false,
    marketing: false,
    thirdPartiesDataTransfer: false,
    specificDataTreatment: false,
  },
};

export const createUserDataSlice = (
  stateValue: UserAgreementsState = initialState
): Slice =>
  createSlice({
    name: 'userAgreements',
    initialState: stateValue,
    reducers: {
      setAgreementsData: (state, action: PayloadAction<Agreements>) => {
        state.agreements.dataTreatment = action.payload.dataTreatment;
        state.agreements.consumerProfiling = action.payload.consumerProfiling;
        state.agreements.marketing = action.payload.marketing;
        state.agreements.thirdPartiesDataTransfer =
          action.payload.thirdPartiesDataTransfer;
        state.agreements.creditEvaluationConsent =
          action.payload.creditEvaluationConsent;
      },
    },
  });

export const userDataSlice = createUserDataSlice();

export const { actions } = userDataSlice;

export default userDataSlice.reducer;

export const agreementsSelector = createSelector(
  (state: RootState) => state.userAgreements,
  (state: UserAgreementsState) => state.agreements
);
