import { configureStore } from '@reduxjs/toolkit';
import { batchDispatchMiddleware } from 'redux-batched-actions';
import login from 'src/features/login/slice';
import loanDetails from 'src/features/merchant/loanDetails/slice';
import userData from 'src/features/merchant/userData/slice';
import notification from 'src/features/notification/slice';
import partner from 'src/features/partner/slice';
import amlEmploymentData from 'src/features/user/aml/employmentData/slice';
import amlPersonalData from 'src/features/user/aml/personalData/slice';
import userAgreements from 'src/features/user/user-recap/slice';

const store = configureStore({
  reducer: {
    login,
    notification,
    loanDetails,
    userData,
    userAgreements,
    amlPersonalData,
    amlEmploymentData,
    partner,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(batchDispatchMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
