import classNames from 'classnames';
import React from 'react';
import styles from './Loader.module.scss';

export type LoaderProps = {
  size: number;
  className?: string;
};

const Loader: React.FC<LoaderProps> = ({ size, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    className={classNames(styles.container, className)}
  >
    <defs>
      <linearGradient id="loader-gradient" gradientTransform="rotate(90)">
      <stop offset="0%" stopColor="var(--color-loader-primary)" />
        <stop offset="100%" stopColor="var(--color-loader-secondary)" />
      </linearGradient>
    </defs>
    <rect x="17" y="33.5" width="8" height="30" fill="url(#loader-gradient)">
      <animate
        attributeName="y"
        repeatCount="indefinite"
        dur="1s"
        keyTimes="0;0.5;1"
        values="21.94;33.5;33.5"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
        begin="-0.2s"
      ></animate>
      <animate
        attributeName="height"
        repeatCount="indefinite"
        dur="1s"
        keyTimes="0;0.5;1"
        values="56.1;33;33"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
        begin="-0.2s"
      ></animate>
    </rect>
    <rect x="42" y="33.5" width="8" height="30" fill="url(#loader-gradient)">
      <animate
        attributeName="y"
        repeatCount="indefinite"
        dur="1s"
        keyTimes="0;0.5;1"
        values="24.8375;33.5;33.5"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
        begin="-0.1s"
      ></animate>
      <animate
        attributeName="height"
        repeatCount="indefinite"
        dur="1s"
        keyTimes="0;0.5;1"
        values="50.325;33;33"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
        begin="-0.1s"
      ></animate>
    </rect>
    <rect x="67" y="33.5" width="8" height="30" fill="url(#loader-gradient)">
      <animate
        attributeName="y"
        repeatCount="indefinite"
        dur="1s"
        calcMode="spline"
        keyTimes="0;0.5;1"
        values="24.8375;33.5;33.5"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
      ></animate>
      <animate
        attributeName="height"
        repeatCount="indefinite"
        dur="1s"
        calcMode="spline"
        keyTimes="0;0.5;1"
        values="50.325;33;33"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
      ></animate>
    </rect>
  </svg>
);

export default Loader;
