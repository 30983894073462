import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { usePartner } from 'src/components/AppWrapper/components/utils/usePartner';
import Button from '../Button';
import FlexContainer from '../FlexContainer';
import OutsideRenderer from '../OutsideRenderer';
import TextLink from '../TextLink';
import styles from './DiscreetModal.module.scss';

export type DiscreetModalProps = {
  closeTitle: string;
  onClose: () => void;
  id: string;
  text: React.ReactNode;
};

const DiscreetModal: React.FC<DiscreetModalProps> = ({
  closeTitle,
  onClose,
  id,
}) => {
  const location = window.location.origin;
  const { t } = useTranslation('common');
  const { partnerName } = usePartner(location);

  return (
    <OutsideRenderer>
      <div data-theme={partnerName}>
        <FlexContainer
          role="dialog"
          aria-modal={true}
          aria-labelledby={`dialog_${id}_label`}
          className={styles.container}
          alignItems="center"
          justifyContent="space-around"
        >
          <FlexContainer className={styles.container__content}>
            <p>
              <Trans
                t={t}
                i18nKey="cookie_banner"
                components={[
                  <TextLink
                    className={styles.textLink}
                    href={'/privacy-policy'}
                    target="_blank"
                    rel="noopener noreferrer"
                  />,
                ]}
              />
            </p>
          </FlexContainer>
          <Button onClick={onClose} title={closeTitle}>
            {closeTitle}
          </Button>
        </FlexContainer>
      </div>
    </OutsideRenderer>
  );
};

export default DiscreetModal;
