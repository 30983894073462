import axios from 'axios';
import { CUSTOMER_TOKEN_KEY, TOKEN_KEY } from 'src/constants';

export const insightsUrl = process.env.REACT_APP_INSIGHTS_BASE_URL;
export const sorgeniaUrl = process.env.REACT_APP_SORGENIA_BASE_URL;
export const fairepayUrl = process.env.REACT_APP_BASE_URL;
export const insightsApiUrl = process.env.REACT_APP_INSIGHTS_API_URL;
export const sorgeniaApiUrl = process.env.REACT_APP_SORGENIA_API_URL;
export const fairepayApiUrl = process.env.REACT_APP_API_URL;

export enum Localhost {
  Fairepay = 'http://localhost:3000',
  Banca_Sella = 'http://localhost:3001',
  Banca_Valsabbina = 'http://localhost:3002',
  Sorgenia = 'http://localhost:3003',
}
const location = window.location.origin;
let baseURL = sorgeniaApiUrl;

const isBancaSella =
  location === fairepayUrl ||
  location === Localhost.Fairepay ||
  location === Localhost.Banca_Sella;
const isBancaValsabbina =
  location === insightsUrl || location === Localhost.Banca_Valsabbina;
const isSorgenia = location === sorgeniaUrl || location === Localhost.Sorgenia;

if (isBancaSella) {
  baseURL = fairepayApiUrl;
}
if (isBancaValsabbina) {
  baseURL = insightsApiUrl;
}
if (isSorgenia) {
  baseURL = sorgeniaApiUrl;
}

const instance = axios.create({
  baseURL: baseURL,
});

instance.interceptors.request.use(
  (config) => {
    const token =
      sessionStorage.getItem(TOKEN_KEY) ||
      sessionStorage.getItem(CUSTOMER_TOKEN_KEY);
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete instance.defaults.headers.common.Authorization;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default instance;
