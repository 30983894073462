import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { IdentificationType, LoanApplicationResponse } from '../../types';

export enum PersonType {
  Natural = 'NATURAL_PERSON',
  Legal = 'LEGAL_PERSON',
}

export type UserDataState = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  fiscalCode: string;
  identificationType: IdentificationType;
  personType: PersonType;
  businessName?: string;
  vatNumber?: string;
} & Partial<Pick<LoanApplicationResponse, 'createdAt' | 'updatedAt' | 'id'>> & {
    customerId?: string;
  };

export type UserMetaData = Pick<
  LoanApplicationResponse,
  'createdAt' | 'id' | 'updatedAt'
>;

export const userDataSliceFromState = (initialState: UserDataState) =>
  createSlice({
    name: 'userData',
    initialState,
    reducers: {
      setUserData: (
        state: UserDataState,
        action: PayloadAction<UserDataState>
      ) => {
        state.firstName = action.payload.firstName;
        state.lastName = action.payload.lastName;
        state.email = action.payload.email;
        state.phoneNumber = action.payload.phoneNumber;
        state.fiscalCode = action.payload.fiscalCode;
        state.customerId = action.payload.customerId ?? state.customerId;
        state.createdAt = action.payload.createdAt;
        state.updatedAt = action.payload.updatedAt;
        state.identificationType = action.payload.identificationType;
        state.id = action.payload.id;
        state.personType = action.payload.personType;
        if (action.payload.personType == PersonType.Legal) {
          state.businessName = action.payload.businessName;
          state.vatNumber = action.payload.vatNumber;
        }
      },
      setIdentificationType: (
        state: UserDataState,
        action: PayloadAction<IdentificationType>
      ) => {
        state.identificationType = action.payload;
      },
    },
  });

export const userDataSlice = userDataSliceFromState({
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  fiscalCode: '',
  identificationType: 'MANUAL',
  id: '',
  customerId: '',
  personType: PersonType.Natural,
});

export const { actions } = userDataSlice;

export default userDataSlice.reducer;

export const customerIdSelector = createSelector(
  (state: RootState) => state.userData,
  (state: UserDataState) => state.customerId
);

export const identificationTypeSelector = createSelector(
  (state: RootState) => state.userData,
  (state: UserDataState) => state.identificationType
);

export const applicationIdSelector = createSelector(
  (state: RootState) => state.userData,
  (state: UserDataState) => state.id
);
