export const EMAIL_PATTERN_REGEX =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const NAMES_REGEX = /^[A-Za-z'\s]*$/;
export const PHONE_NUMBER_REGEX = /^[0-9]*$/;
export const HELP_ONBOARDING_FORM_ID = 'help-onboarding-form';
export const TOKEN_KEY = 'fairepay-token';
export const CUSTOMER_TOKEN_KEY = 'fairepay-customer-token';
export const FISCAL_CODE_REGEX =
  /^([A-Z]{6}[\dLMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[\dLMNPQRSTUV]{2}[A-Z]{1}[\dLMNPQRSTUV]{3}[A-Z]{1})$/;
export const VAT_NUMBER_REGEX = /^([0-9]{11})$/;
