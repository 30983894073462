import React from 'react';
import * as Sentry from '@sentry/react';

const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  const handleOnError = (error: any) => {
    Sentry.captureException(error)
  }

  return <Sentry.ErrorBoundary onError={handleOnError}>{children}</Sentry.ErrorBoundary>;
};

export default ErrorBoundary;