import React from 'react';
import ContainerFluid from 'src/components/lib/ContainerFluid/ContainerFluid';
import FlexContainer from 'src/components/lib/FlexContainer';
import { useMatch } from 'react-router-dom';
import styles from './Main.module.scss';

interface Props {
  children?: React.ReactNode;
}
const Main: React.FC<Props> = ({ children }) => {
  const match = useMatch('/help-form/*');

  if (match) {
    return (
    <ContainerFluid className={styles.container__marginBottom}>
      <FlexContainer className={styles.container__content}>
        {children}
      </FlexContainer>
    </ContainerFluid>
    )
  }
  return (
  <main>
    <ContainerFluid className={styles.container}>
      <FlexContainer className={styles.container__content}>
        {children}
      </FlexContainer>
    </ContainerFluid>
  </main>
  );
};

export default Main;
